

@import './style/theme.scss';
@import './style/utils.scss';
@import 'bootstrap-icons/font/bootstrap-icons.css';


.text-underline-warning {
    background:linear-gradient(
        0deg, rgba(255,255,255,0) 0%,
        rgba(255,255,255,0) 15%,
        #ffd028 15%,
        #ffd028 30%,
        rgba(255,255,255,0) 30%,
        rgba(255,255,255,0) 100%
      )
}


/*-------------------------------------------------------
-------------- Subscription Form Styling ----------------
-------------------------------------------------------*/


.cta-sm {
	padding: 100px 0 100px 0;
	background: rgba(30, 166, 154, 0.1);
	background: #f8faff;
	background: #f6f6fd;
}

.cta-sm .cta-content {
	max-width: 500px;
	margin: 0 auto;
}

.cta-sm h1 {
	font-size: 28px;
	font-weight: 600;
	color: #364655;
	line-height: 1.6;
}

.cta-sm h4 {
	font-size: 13px;
	font-weight: 400;
	color: #aeaeae;
	letter-spacing: 1px;
	margin-bottom: 0;
}


.form-note p {
	font-size: 12px;
	font-weight: 500;
	color: #97a6b5
}
