//Your customizations must come before bootstrap import

// $enable-shadows: true;
$primary: #7805f7;


// $body-color:  #333;
// $body-bg: #f2f2f2;

@import "~bootstrap/scss/functions";
@import  "~bootstrap/scss/variables";
@import  "~bootstrap/scss/mixins/_breakpoints";
@import  "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');