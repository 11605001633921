.clients01 {
    text-align: center;
    padding-top: 3em;
    padding-bottom: 3em;
}

.clients01__list {
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -40px
}

.clients01__item {
    margin: 0 30px 40px
}

.clients01__logo {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: -webkit-filter 0.15s ease;
    transition: filter 0.15s ease;
    transition: filter 0.15s ease, -webkit-filter 0.15s ease
}

.clients01__logo:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}
