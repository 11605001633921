.header01 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.header01 h1 {
  color: #444;
  font-weight: 600;
}

.header01__img {
  max-height: 450px;
}
