 body {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 450;
  }

  h2 {
    font-weight: 800;
    padding-bottom: 0.5em;
  }

  footer.footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
