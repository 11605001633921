.features01 {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.features01 .h3,
.features01 h3 {
  font-size: 1.5rem;
}

.features01 .features01-item {
  max-width: 20rem;
}

.features01 .features01-item .features01-icon {
  height: 7rem;
}

.features01 .features01-item .features01-icon i {
  font-size: 3rem;
}

.features01 .features01-item:hover .features01-icon i {
  font-size: 3.5rem;
}

.features01 .features01-item .features01-icon span {
  font-size: 3rem;
}

.features01 .features01-item:hover .features01-icon span {
  font-size: 3.5rem;
}
